

import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getForm } from "../../features/form/formSlice"
import { useNavigate, useParams } from "react-router-dom"
import { getSubmissions } from "../../features/submissions/submissionsSlice"
import { SubmissionGrid } from "react-formio/lib/components"

function SubmissionList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useParams();
    const { id } = state;
    const submissions = useSelector(state => state.submissions.submissions);
    const form = useSelector(state => state.form.form);
    const pagination = useSelector(state => state.submissions.pagination);
    useEffect(() => {
        if (id) {
            dispatch(getSubmissions({
                id
            }))
            dispatch(getForm({
                id
            }))
        }

    }, [id])
    const onSubmitHandler = () => {
        navigate(`/submission/${submissions._id}/list`)
    }

    return (
        <>
            {console.log(form)}
            {id && form._id && <SubmissionGrid submissions={{ submissions: submissions, pagination: pagination }} form={form} getSubmissions={getSubmissions} />}
        </>
    )
}
export default SubmissionList