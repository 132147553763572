
// Package Imports
import { Form } from "@formio/react";
import { uniqueId } from "lodash";

const OForm = ({ src, defaultData, onSubmit }) => {
    const onSubmitHandler = function (submission) {
        console.log(submission);
        if (typeof onSubmit == 'function') {
            onSubmit(submission)
        }
    };
    return (
        <>

            <Form
                src={src}
                onSubmit={onSubmitHandler}
                submission={{ data: { oid: uniqueId('oid'), ...defaultData } }}
                onError={console.error}
            />
        </>
    );
};

export default OForm;
