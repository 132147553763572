// Package Imports
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';
import { logout, userLogout } from "../features/auth/authSlice";

// Project Imports
import logo from "../augmentLogo.jpg";
import keycloak from "../keycloak/Keycloak";

const { Header, Content, Footer } = Layout;

function CustomNavigation() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
        <Layout>
            <Header className="layout-header">
                <div className="formio-brand container">
                    <img src={logo} style={{ height: "48px" }}></img>
                    <div className="layout-header-links-wrapper">
                        <Link to="forms" className="nav-link-item">Forms Manager</Link>
                        <Link to="forms/add" className="nav-link-item">Add Form</Link>
                        {user ?
                            <Button type="primary" size="large" className="log-in-out-button" onClick={() => { dispatch(logout()) }}>Logout</Button>
                            :
                            <Button type="primary" size="large" className="log-in-out-button">
                                <Link to="login" className="nav-link-item">
                                    <i className="fa fa-lock me-1"></i>
                                    <span>Login</span>
                                </Link>
                            </Button>
                        }
                    </div>
                </div>
            </Header>
        </Layout>
    );
}

export default CustomNavigation;
