/* other imports */

import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import LoginForm from "./components/Login/Login";
import FormManagerEdit from "./components/Form-manager/Form-manager-edit";
import FormManager from "./components/Form-manager/Form-manager";
import SubmissionForm from "./components/Submission/SubmissionForm";
import SubmissionList from "./components/Submission/SubmissionList";
import FormBuilderPage from "./components/FormBuilderPage";
import AdminTemplate from "./components/Template/AdminTemplate";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                element: <LoginForm />,
            },
            {
                path: "login",
                element: <LoginForm />,
            },
            {
                path: "forms/add",
                element: <AdminTemplate><FormManagerEdit /></AdminTemplate>,
            },
            {
                path: "forms/:id/edit",
                element: <AdminTemplate><FormManagerEdit /></AdminTemplate>,
            },
            {
                path: "forms",
                element: <AdminTemplate><FormManager /></AdminTemplate>,
            },
            {
                path: "submission/:id/list",
                element: <AdminTemplate><SubmissionList /></AdminTemplate>,
            },
            {
                path: "submission/:id",
                element: <SubmissionForm />,
            },
        ],
    },
]);

export default router;