import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import formiojs from '../../formio';

const initialState = {
    init: false,
    isActive: false,
    user: null,
    authenticated: false,
    submissionAccess: {},
    formAccess: {},
    projectAccess: {},
    roles: {},
    is: {},
    error: '',
};

function mapProjectRolesToUserRoles(projectRoles, userRoles) {
    return Object.entries(projectRoles).reduce(
        (result, [name, role]) => ({
            ...result,
            [name]: userRoles.includes(role._id),
        }),
        {},
    );
}

function getUserRoles(projectRoles) {
    return Object.keys(projectRoles).reduce(
        (result, name) => ({
            ...result,
            [name]: name === 'anonymous',
        }),
        {},
    );
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userRequest: (state) => {
            state.init = false;
            state.isActive = true;
            state.submissionAccess = false;
        },
        userRequestSuccess: (state, action) => {
            if (action.payload) {

                state.isActive = false;
                state.user = action.payload;
                state.authenticated = true;
                state.is = mapProjectRolesToUserRoles(
                    state.roles,
                    action.payload.roles
                );
                state.error = '';
            }
            state.init = true;

        },

        userRequestFailure: (state, action) => {
            state.isActive = false;
            state.is = getUserRoles(state.roles);
            state.error = action.payload.error;
            window.location.href = "/login";
        },
        userLogout: (state,action) => {
            state.user = null;
            state.isActive = false;
            state.authenticated = false;
            state.is = getUserRoles(state.roles);
            localStorage.clear();
            state.error = '';
            window.location.href = "/login";
        },
        userSubmissionAccess: (state, action) => {
            state.submissionAccess = action.payload.submissionAccess;
        },
        userFormAccess: (state, action) => {
            state.formAccess = action.payload.formAccess;
        },
        userProjectAccess: (state, action) => {
            state.projectAccess = action.payload.projectAccess;
        },
        userRoles: (state, action) => {
            state.roles = action.payload.roles;
        },
    },
});

export const {
    userRequest,
    userRequestSuccess,
    userRequestFailure,
    userLogout,
    userSubmissionAccess,
    userFormAccess,
    userProjectAccess,
    userRoles,
} = authSlice.actions;



export const initAuth = createAsyncThunk(
    'auth/initAuth',
    async (_, { dispatch }) => {
        const projectUrl = formiojs.getProjectUrl();
        console.log('projectUrl', projectUrl)

        dispatch(userRequest());
        try {
            const [user] = await Promise.all([
                formiojs.currentUser(),
                formiojs.makeStaticRequest(`${projectUrl}/access`).then((result) => {
                    const submissionAccess = transformSubmissionAccess(result.forms);
                    const formAccess = transformFormAccess(result.forms);

                    dispatch(userSubmissionAccess({ submissionAccess }));
                    dispatch(userFormAccess({ formAccess }));
                    dispatch(userRoles({ roles: result.roles }));
                }),
            ]);
            console.log('user response', user)
            if (user) {
                dispatch(userRequestSuccess(user));
            } else {
                dispatch(userRequestSuccess());
            }
        } catch (error) {
            console.error(error)
            dispatch(userRequestFailure(error));
        }
    }
);

export const setUser = createAsyncThunk(
    'auth/setUser',
    async (user, { dispatch }) => {
        console.log('auth/setUser', user)
        formiojs.setUser(user);
        dispatch(userRequestSuccess(user));
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { dispatch }) => {
        try {
            await formiojs.logout();
        } catch (error) {
            console.error(error)
        }
        dispatch(userLogout());
    }
);

function transformSubmissionAccess(forms) {
    return Object.values(forms).reduce(
        (result, form) => ({
            ...result,
            [form.name]: form.submissionAccess.reduce(
                (formSubmissionAccess, access) => ({
                    ...formSubmissionAccess,
                    [access.type]: access.roles,
                }),
                {}
            ),
        }),
        {}
    );
}

function transformFormAccess(forms) {
    return Object.values(forms).reduce(
        (result, form) => ({
            ...result,
            [form.name]: form.access.reduce(
                (formAccess, access) => ({
                    ...formAccess,
                    [access.type]: access.roles,
                }),
                {}
            ),
        }),
        {}
    );
}

function transformProjectAccess(projectAccess) {
    return projectAccess?.reduce(
        (result, access) => ({
            ...result,
            [access.type]: access.roles,
        }),
        {}
    );
}
export default authSlice.reducer;
