
//Project Imports
import "./custom-breadcrumb.css";

const CustomBreadcrumb = ({ title, icon, children }) => {
    return (
        <div className="custom-breadcrumb">
            {icon && (
                <div className="custom-breadcrumb-icon">
                    {icon}
                </div>
            )}
            <div className="custom-breadcrumb-title">{title}</div>
            {children}

        </div>
    );
};

export default CustomBreadcrumb;