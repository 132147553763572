
const viewportWidth = window.innerWidth;
const colors = {
    // primary: '#4F46E5',
    // yellow: '#FCC015',
    primary: '#274ECB',
    secondary: '#F5BF13',
    placeholder: '#808080',
    heading: '#5A4FCF',
    subheading: '#E6E6E6',
    white: '#FFFFFF',
    black: '#000000',
    mutedPrimary: '#021E4C',
    mutedSecondary: '#BD9D64',
    mutedWhite: '#EBEBEB',
    mutedBlack: '#908C89',
    titleColor: '#858585',
    menuTitleColor: '#333333',
    formColor: '#f5f5f5',
    selectBorder: '#D9D9D9',
};
const ConfigStyle = {
    theme: {
        components: {
            Button: {
                borderRadius: 0,
                colorPrimary: colors.primary,
                primaryColor: colors.white,
                colorPrimaryHover: colors.mutedPrimary,
            },
            Layout: {
                siderBg: '#FF0000',
                headerColor: colors.mutedBlack,
            },
            Table: {
                colorTextHeading: colors.black,
                // colorBgContainer: "#fff",
            },
            ...(viewportWidth > 900 && {
                Menu: {
                    // subMenuItemBg: colors.white,
                    // itemSelectedColor: colors.black,
                    // itemSelectedBg: colors.secondary,
                    // itemBg: colors.white,
                    // itemColor: '#6c6666',
                    // itemHoverColor: colors.black,
                    // itemHoverBg: colors.secondary,

                    subMenuItemBg: colors.menuTitleColor,
                    itemSelectedColor: colors.menuTitleColor,
                    itemSelectedBg: colors.formColor,
                    itemBg: colors.mutedPrimary,
                    itemColor: colors.titleColor,
                    itemHoverColor: colors.menuTitleColor,
                    itemHoverBg: colors.formColor,
                },
            }),
            // Card: {
            //   colorTextHeading: colors.heading,
            //   colorBgContainer: '#fff',
            //   colorPrimary: '#001E4C',
            // },
            // Form: {
            //   colorTextHeading: 'black',
            //   labelColor: 'black',
            // },
            Input: {
                colorTextPlaceholder: colors.mutedBlack,
            },
            Checkbox: {
                colorPrimary: colors.primary,
            },
            Select: {
                selectorBg: colors.white,
                colorBorder: colors.selectBorder,
                optionSelectedColor: colors.white,
                optionSelectedFontWeight: 800,
                colorBgElevated: colors.white,
                optionSelectedBg: colors.primary,
                optionActiveBg: colors.mutedWhite,
                optionFontSize: 16,
            },
            Radio: {
                buttonColor: colors.primary,
                buttonBg: colors.primary,
                buttonCheckedBg: colors.primary,
                colorPrimaryActive: colors.primary,
                buttonSolidCheckedColor: colors.primary,
                buttonSolidCheckedBg: colors.primary,
                buttonSolidCheckedActiveBg: colors.primary,
                // radioSize: 16,
                // dotSize: 8,
            },
            //   DatePicker: {
            //     colorTextPlaceholder: colors.placeholder,
            //   },
            //   Dropdown: {
            //     colorTextPlaceholder: colors.placeholder,
            //   },
            Typography: {
                colorTextHeading: colors.menuTitleColor,
                // fontFamily: 'Inter',
            },
            Tree: {},
            Segmented: {
                itemSelectedBg: colors.primary,
                itemSelectedColor: colors.white,
                trackBg: colors.subheading,
            },
        },

        token: {
            fontFamily: `'Noto Sans','san-serif'`,
            fontSize: 16,
            colorBgBase: colors.white,
            colorBgContainer: colors.white,
            // colorPrimaryHover: colors.primary,
        },

        // h2: {
        //   fontWeightStrong: true,
        // },
    },

    componentSize: 'large',
    labelfontWeight: '600',
    labelColor: colors.black,
    colors,
};

export default ConfigStyle;