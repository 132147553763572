import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _pick from 'lodash/pick';
import { selectRoot } from '@formio/react';
import formiojs from '../../formio';

const initialState = {
    error: '',
    forms: [],
    isActive: false,
    limit: 10,
    pagination: {
        numPages: 0,
        page: 1,
        total: 0,
    },
    query: {},
    select: {},
    sort: '',
};


const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        resetForms: () => (initialState),
        requestForms: (state, action) => ({
            ...state,
            ..._pick(action.payload.params, ['limit', 'query', 'select', 'sort']),
            error: '',
            forms: [],
            isActive: true,
            pagination: {
                ...state.pagination,
                page: action.payload.page,
            },
        }),
        successForms: (state, action) => {
            const total = action.payload.serverCount;
            return {
                ...state,
                forms: action.payload,
                isActive: false,
                pagination: {
                    ...state.pagination,
                    numPages: Math.ceil(total / state.limit),
                    total,
                },
            };
        },
        failForms: (state, action) => ({
            ...state,
            error: action.payload.error,
            isActive: false,
        }),
    },
});
export const indexForms = createAsyncThunk(
    'forms/indexForms',
    async ({ page, params }, { dispatch, getState }) => {
        try {
            const projectUrl = formiojs.getProjectUrl();
            console.log('forms/indexForms projectUrl', projectUrl, page, params, getState())
            dispatch(requestForms({ page, params }));



            const formio = new formiojs(`${formiojs.getProjectUrl()}/form`);
            const { limit, query, select, sort } = selectRoot('forms', getState());
            const requestParams = { ...query, ...params };

            // Ten is the default so if set to 10, don't send.
            if (limit !== 10) {
                requestParams.limit = limit;
            } else {
                delete requestParams.limit;
            }

            if (page !== 1) {
                requestParams.skip = (page - 1) * limit;
            } else {
                delete requestParams.skip;
            }

            delete requestParams.select;
            requestParams.type = 'form';

            if (sort) {
                requestParams.sort = sort;
            } else {
                delete requestParams.sort;
            }
            return formio
                .loadForms({ params: requestParams })
                .then((result) => {
                    dispatch(successForms(result));
                })
                .catch((error) => {
                    dispatch(failForms(error));
                });
        } catch (error) {
            console.error(error)
        }
    }
);
export const { resetForms, requestForms, successForms, failForms } = formsSlice.actions;

export default formsSlice.reducer;
