// Package Imports
import { useEffect } from "react";
import { ConfigProvider } from "antd";
import { useDispatch } from 'react-redux';
import { Outlet } from "react-router-dom";
import { Components } from "react-formio";

// Project Imports
import "./App.css";
import './styles/glyphicons-halflings/css/glyphicons-halflings.css';
import './styles/fontawsome/css/fontawsome.css';
import { initAuth } from "./features/auth/authSlice";
import { setError } from "./features/error/errorSlice";
import formiojs from "./formio";
import FormioCustomComponent from "./components/modules/formio-custom-components-package/custom";
import ConfigStyle from "./constant/ConfigStyle";
import CustomNavigation from "./components/CustomNavigation";

// Formio.use(FormioContrib);
// Formio.use(custom);
Components.setComponents(FormioCustomComponent)
function App() {
  const dispatch = useDispatch();
  formiojs.fetch.addResponseInterceptor(async (response) => {
    // You can access the response properties or headers here if needed
    console.log('Response Interceptor:', response);
    if (!response.ok) {
      const responseClone = response.clone(); // Use clone to read the body multiple times
      const error = await responseClone.json();
      console.log(' error', error);
      // Assuming `dispatch` is available in the scope
      dispatch(setError(error));
    }
    return response;
  });
  useEffect(() => {
    dispatch(initAuth());
  }, [])
  return (
    <ConfigProvider {...ConfigStyle}>
      <div className="App">
        <div className="mb-5">
          <CustomNavigation />
        </div>
        <div className="container">
          <Outlet />
        </div>
    </div>
    </ConfigProvider>
  );
}

export default App;
