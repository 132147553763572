// Package Imports
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

// Project Imports
import OForm from "../OForm/OForm"
import { setUser } from "../../features/auth/authSlice"
import FormUtils from "../modules/formio-custom-components-package/utils/FormUtils"

function LoginForm() {
    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    const defaultFormData = {
        email: '',
        password: ''
    }
    const dispatch = useDispatch();

    const onSubmitHandler = (resData) => {
        dispatch(setUser(resData));
        console.log('Login Successful!')
    }
    useEffect(() => {
        if (user) {
            navigate('/forms')
        }
    }, [user])
    return (
        <>
            <div className="card login-form-card p-3 pt-5">
                <h5 className="card-title login-form-card-title">Login</h5>
                <div className="card-body rounded-3 login-form">
                    <OForm src={FormUtils.getFormUrlByPath('user/login')} defaultData={defaultFormData} onSubmit={onSubmitHandler}></OForm>
                </div>
            </div>
        </>
    )
}
export default LoginForm