// store.js

import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/auth/authSlice';
import formsSlice from './features/forms/formsSlice';
import formSlice from './features/form/formSlice';
import submissionsSlice from './features/submissions/submissionsSlice';
import errorSlice from './features/error/errorSlice';

const store = configureStore({
    reducer: { auth: authSlice, forms: formsSlice, form: formSlice, submissions: submissionsSlice, error: errorSlice }
})

export default store;
