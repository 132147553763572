

import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getForm, saveFormHandler } from "../../features/form/formSlice"
import { useNavigate, useParams } from "react-router-dom"
import OForm from "../OForm/OForm"
import FormUtils from "../modules/formio-custom-components-package/utils/FormUtils"

function SubmissionForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useParams();
    const { id } = state;
    const form = useSelector(state => state.form.form);
    useEffect(() => {
        if (id) {
            dispatch(getForm({
                id
            }))
        }
    }, [id])
    const onSubmitHandler = () => {
        navigate(`/submission/${form._id}/list`)
    }

    return (
        <>
            {id && form._id && <OForm src={FormUtils.getFormUrlByPath(form.path)} onSubmit={onSubmitHandler} defaultData={{ id: '19359', ceqId: '19359', ceqName: "Deepesh", contractId: "28027", timelineId: '12643', adjustmentId: "CVC" }} />}
        </>
    )
}
export default SubmissionForm