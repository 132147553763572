import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _pick from 'lodash/pick';
import { selectRoot } from '@formio/react';
import formiojs from '../../formio';

const initialState = {
    error: '',
    formId: '',
    isActive: false,
    limit: 10,
    pagination: {
        numPages: 0,
        page: 1,
        total: 0,
    },
    query: {},
    select: {},
    sort: "",
    submissions: [],
};


const submissionsSlice = createSlice({
    name: 'submissions',
    initialState,
    reducers: {
        resetSubmissions(state) {
            return initialState;
        },
        requestSubmissions(state, action) {
            if (action.payload.name !== state.name) {
                return state;
            }
            return {
                ...state,
                ..._pick(action.payload.params, ['limit', 'query', 'select', 'sort']),
                error: '',
                formId: action.payload.formId,
                isActive: true,
                pagination: {
                    ...state.pagination,
                    page: action.payload.page,
                },
                submissions: [],
            };
        },
        successSubmissions(state, action) {
            const total = action.payload.submissions.serverCount;

            return {
                ...state,
                isActive: false,
                pagination: {
                    ...state.pagination,
                    numPages: Math.ceil(total / state.limit),
                    total,
                },
                submissions: action.payload.submissions,
            };
        },
        failureSubmissions(state, action) {
            return {
                ...state,
                error: action.payload.error,
                isActive: false,
            };
        },
    },
});
export const {
    resetSubmissions,
    requestSubmissions,
    successSubmissions,
    failureSubmissions, } = submissionsSlice.actions;


// Async thunk for getting a form
export const getSubmissions = createAsyncThunk('submissions/getSubmissions', async (params, { dispatch, getState }) => {
    const { name, page = 1, id, done = () => { } } = params;
    const formId = id;
    dispatch(requestSubmissions({ name, page, params, formId }));
    const { limit, query, select, sort } = selectRoot('submissions', getState());
    const formio = new formiojs(
        `${formiojs.getProjectUrl()}/${formId ? `form/${formId}` : name}/submission`,
    );
    const requestParams = { ...query, ...params };

    // Ten is the default so if set to 10, don't send.
    if (limit !== 10) {
        requestParams.limit = limit;
    } else {
        delete requestParams.limit;
    }

    if (page !== 1) {
        requestParams.skip = (page - 1) * limit;
    } else {
        delete requestParams.skip;
    }

    delete requestParams.select;

    if (sort) {
        requestParams.sort = sort;
    } else {
        delete requestParams.sort;
    }

    return formio
        .loadSubmissions({ params: requestParams })
        .then((result) => {
            dispatch(successSubmissions(name, result));
            done(null, result);
        })
        .catch((error) => {
            dispatch(failureSubmissions(name, error));
            done(error);
        });
});


export default submissionsSlice.reducer;
