// Package Imports
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux'; // Import Provider from react-redux
import { ReactKeycloakProvider } from "@react-keycloak/web";

// Project Imports
import "./index.css";
import "./components/modules/formio-custom-components-package/css/index.css";
import reportWebVitals from "./reportWebVitals";
import store from './store';
import router from "./router";
import keycloak from "./keycloak/Keycloak";
const root = ReactDOM.createRoot(document.getElementById("root"));

function onKeycloakEvent(type, error) {
  console.log("keycloak event ", type, keycloak.authenticated);

  if (keycloak.authenticated == false) {
    //
    keycloak.login();
  }
  if (type && type == "onAuthSuccess") {
    // sendNotification('login', keycloak);
  }
}

function onKeycloakTokenReceived(token) {
  if (token.idToken) {
    // getUerProfile();
  } else {
    // sendNotification('logout', keycloak.sessionId);
  }
}

root.render(
  // <ReactKeycloakProvider
  //   authClient={keycloak}
  //   onEvent={onKeycloakEvent}
  //   onTokens={onKeycloakTokenReceived}
  //   initOptions={{
  //     onLoad: "check-sso",
  //     silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  //     pkceMethod: "S256",
  //   }}
  // >
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  // </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
