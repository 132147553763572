import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminTemplate = ({ children }) => {
    const user = useSelector(state => state.auth.user);
    const userInit = useSelector(state => state.auth.init);
    const navigate = useNavigate();
    useEffect(() => {
        if (!user && userInit) {
            navigate('/login')
        }
    }, [user,userInit])
    return (
        <>
            { children }
        </>
    )
}

export default AdminTemplate;