//Package  Import
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Table, Button, Popconfirm, Space } from "antd";
import { EditOutlined, DeleteOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';

//Project Import
import { indexForms } from "../../features/forms/formsSlice";
import { deleteFormHanlder } from "../../features/form/formSlice"
import "./form-manager.css";
import CustomBreadcrumb from "../Custom-breadcrumb/Custom-breadcrumb";



function FormManager() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const forms = useSelector((state) => state.forms.forms);
    const pagination = useSelector((state) => state.forms.pagination);
    useEffect(() => {
        dispatch(
            indexForms({
                page: 1,
                params: {},
            })
        );
    }, [dispatch]);

    const getForms = (page) => {
        dispatch(
            indexForms({
                page,
            })
        );
    };

    const actionHandler = (data, action) => {
        switch (action) {
            case "edit":
                navigate(`/forms/${data._id}/edit`);
                break;
            case "view":
                navigate(`/submission/${data._id}`);
                break;
            case "submission":
                navigate(`/submission/${data._id}/list`);
                break;
            case "delete":
                if (!window.confirm("Are you sure?")) return;
                dispatch(
                    deleteFormHanlder({
                        id: data._id,
                        done: () => {
                            dispatch(
                                indexForms({
                                    page: 1,
                                    params: {},
                                })
                            );
                        },
                    })
                );
                break;
            default:
                break;
        }
    };
    const handleButtonClick = () => {
        // Add your logic here
        console.log("Button clicked!");
        // Navigate to the desired route
        navigate('/forms/add');
    };
    return (
        <>
            <CustomBreadcrumb title="Forms Manager"
            ><Button onClick={handleButtonClick} className="form-manager-create-button" icon={<PlusOutlined />}
            >Create New Form</Button>
            </CustomBreadcrumb>
            <Table
                className="form-manger-table"
                dataSource={forms}
                rowKey="_id"
                pagination={{
                    current: pagination.page,
                    total: pagination.total,
                    pageSize: pagination.limit,
                    onChange: getForms,
                }} >
                <Table.Column dataIndex="name" title="Form" />
                <Table.Column width="300px" dataIndex="operation" title="Operation"
                    render={(row, record) => (
                        <Space size="large">
                            <Button icon={<FormOutlined />} className="form-manager-edit-button" shape="round" type="primary" onClick={() => actionHandler(record, "view")}>
                                Enter Data
                            </Button>
                            <Button type="secondary" className="form-manager-edit-icon" icon={<EditOutlined />} onClick={() => actionHandler(record, "edit")} >
                            </Button>
                            <Popconfirm
                                title="Are you sure to delete this form?"
                                className="form-manager-delete-icon"
                                onConfirm={() => actionHandler(record, "delete")}
                            >
                                <Button className="form-manager-delete-button" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    )} />
            </Table>
        </>
    );
}

export default FormManager;
