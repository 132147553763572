import { Formio as formiojs } from '@formio/js';
import EnvConstant from './constant/EnvConstant';
import { getEnvData } from './components/modules/formio-custom-components-package/utils/getEnvData';
const { fetch: originalFetch } = window;
const responseInterceptors = [];
const fetch = async (...args) => {
  let [resource, config] = args;

  console.log('', resource, config)
  // request interceptor ends
  try {

    const response = await originalFetch(resource, config);
    const clonedResponse = response.clone();

    // Pass the cloned response through interceptors
    for (const interceptor of responseInterceptors) {
      interceptor(clonedResponse);
    }
    // response interceptor here
    switch (response.status) {
      case 400:
        console.error(response)
        break;
      case 401:
        alert(response.statusText);
        window.localStorage.clear();
        window.location.href = "/login";
        console.error(response)
        break;

      default:
        break;
    }
    return response;

  } catch (error) {
    console.error(error)
  }
};

fetch.addResponseInterceptor = function (interceptor) {
  responseInterceptors.push(interceptor);
};

formiojs.setProjectUrl(getEnvData(EnvConstant.FORM_BASE_URL));

formiojs.fetch = fetch;

export default formiojs;