import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Errors, FormBuilder, FormEdit, FormGrid } from "react-formio/lib/components"
import { getForm, resetForm, saveFormHandler } from "../../features/form/formSlice"
import { useNavigate, useParams } from "react-router-dom"
import { setError } from "../../features/error/errorSlice"

function FormManagerEdit() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useParams();
    const { id } = state;
    const form = useSelector(state => state.form.form);
    const error = useSelector(state => state.error.error);
    useEffect(() => {
        if (id) {
            dispatch(getForm({
                id
            }))
        }
    }, [id])
    useEffect(() => {
        dispatch(setError([]));
        dispatch(resetForm([]));
    }, [])
    const saveForm = (form) => {
        dispatch(saveFormHandler({
            id, form, done: (error) => {
                if (!error) {
                    navigate(`/forms`)
                }
            }
        }))
    }


    const apiNameDropdown = () => {
        // Array of objects with id and name properties
        var arrayOfObjects = [
            { id: 'o1', name: 'Option 1' },
            { id: 'o2', name: 'Option 2' },
            { id: 'o3', name: 'Option 3' }
        ];

        // Find the existing input element by name
        var inputElement = document.querySelector('input[name="data[key]"]');
        var selectElement = document.querySelector(`#${inputElement.getAttribute('id')}-select`);
        if (selectElement) {
            selectElement.remove()
        }
        // Check if the input element is found
        if (inputElement) {
            // Create a new select element
            var selectElement = document.createElement('select');
            selectElement.classList.add('form-select')
            // Set attributes for the new select element
            selectElement.onchange = function (e) {
                var event = new Event('input');

                // Dispatch the change event on the select element

                inputElement.value = e.target.value + '';
                inputElement.dispatchEvent(event);

            };
            selectElement.setAttribute('id', inputElement.getAttribute('id') + '-select');

            // Iterate through the array and create options for the select element
            arrayOfObjects.forEach(function (obj) {
                var option = document.createElement('option');
                option.value = obj.id;
                option.text = obj.name;
                selectElement.appendChild(option);
            });
            // inputElement.classList.add('d-none')
            // Replace the existing input element with the new select element
            inputElement.parentNode.appendChild(selectElement);

            console.log("Input element replaced with select element:", selectElement);
        } else {
            console.log("Input element not found");
        }

    }
    const onChangeHanlder = (schema) => {
        console.log(schema)
    }
    return (
        <>
            {error && <Errors errors={error} />}
            {/* {((id && form._id) || true) && <FormBuilder form={form} onUpdateComponent={() => {
                apiNameDropdown()
            }}
                onChange={onChangeHanlder} />} */}
            {((id && form._id) || true) && <FormEdit form={form} saveText="Save" saveForm={saveForm} />}
        </>
    )
}
export default FormManagerEdit